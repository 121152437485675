import {useHistory} from "react-router-dom";

import "./BackButton.scss";

import arrow from "../../Assets/back-arrow.svg";

export default function BackButton(props) {
    //hooks
    const history=useHistory();

    //props
    const setAnimStyle=props.setAnimStyle;

    const goBack = ()=>{
        setAnimStyle(["anim-exit", null]);
        setTimeout(()=>newScreenIn(), 400);
    }

    function newScreenIn() {
        //this function slides in the new screen
        setAnimStyle(["anim-fast", null]);
        history.goBack();
    }

    return (
        <button onClick={()=>{goBack()}} className="back-button">
            <img src={arrow} className="back-button__arrow"/>
            <div >
                Back
            </div>
        </button>
    )
}