import "./Postal.scss";

import BackButton from "../../BackButton/BackButton";

//import image
import titleImg from "../../../Assets/postal/postal-logo.png"

export default function Postal(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`postal-box ${animClass}`}>
            <div className="postal-content">
                <div className="postal-title" >Postal Productions</div>
                
                <section className="ls-description">
                    <a href="https://www.postalproductions.ca/" className="unstyled-link">
                        <img src={titleImg} alt="postal app home screen" className="postal-img"/>
                    </a>
                    <div className="postal-desc__info">
                        <div className="postal-desc__header">What is it?</div>
                        <div className="postal-desc__description">I built the postal productions website as a freelance project during and directly after graduating from my web development boot camp. The team at postal were amazing first clients, and I had a great experience working for them. The site is now live, and can be found <a href="https://www.postalproductions.ca/" className="unstyled-link">here</a>
                        </div>
                        <ul className="postal-desc__stats">
                            <li className="postal-desc__stat">Tech Stack: HTML, CSS, Sass</li>
                            <li className="postal-desc__stat">Timeline: 2 months, Nov 2020 to Jan 2021 (part time)</li>
                        </ul>
                    </div>
                </section>
                <BackButton setAnimStyle={setAnimStyle} />
            </div>
        </div>
    )
}