import "./Labyrinth.scss";

import BackButton from "../../BackButton/BackButton";

//import images
import mazeImg from "../../../Assets/labyrinth/labyrinth-maze.PNG";
import mazeImg2 from "../../../Assets/labyrinth/labyrinth-bigmaze.PNG";
import titleImg from "../../../Assets/labyrinth/labyrinth-halls.PNG";
import pauseImg from "../../../Assets/labyrinth/labyrinth-pause.PNG";

export default function Labyrinth(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`labyrinth-box ${animClass}`}>
            <div className="labyrinth-content">
                <div className="labyrinth-title" >Labyrinth of Souls</div>
                
                <section className="laby-desc">
                    <div className="labyrinth-imagebox" >
                        <img src={titleImg} alt="labyrinth gameplay sceenshot" className="labyrinth-img"/>
                        <img src={pauseImg} alt="labyrinth gameplay sceenshot" className="labyrinth-img"/>
                    </div>
                    <div className="laby-desc__info">
                        <div className="laby-desc__header">What is it?</div>
                        <div className="laby-desc__description">Labyrinth of souls was a game made over the summer of 2020. It was my first real project with the Unity game engine, and was a great experience. Gameplay involves our protagonist, a nondescript blue orb (I'm a programmer, not an artist) battling against endless hordes of ghosts in a procedurally generated dark labyrinth lit by sparse lamps. Activating three pedestals opens the escape portal - jump in to escape!</div>
                        <ul className="laby-desc__stats">
                            <li className="laby-desc__stat">Tech Stack: Unity, C++</li>
                            <li className="laby-desc__stat">Timeline: 4 weeks, August 2020</li>
                        </ul>
                    </div>
                </section>
                <section className="laby-deets" >
                    <div className="laby-desc__header">About the labyrinth</div>
                    <div className="labyrinth-imagebox" >
                        <img src={mazeImg} alt="labyrinth gameplay sceenshot" className="labyrinth-img"/>
                        <img src={mazeImg2} alt="labyrinth gameplay sceenshot" className="labyrinth-img"/>
                    </div>
                    <div className="laby-deets__description">The procedural generation of the labyrinth randomly places a set number of rooms, either square or circular, on a grid. The rooms are then interconnected by hallway passges, and special items like pedestals, ghost spawners, and the portal are placed. Once the grid is fully generated all 3D assets are spawned in. The labyrinth is different every time!</div>
                </section>
                <BackButton setAnimStyle={setAnimStyle} />
            </div>
        </div>
    )
}