//import styling
import "./Header.scss";

//import package dependencies
import {useHistory, useLocation} from "react-router-dom";
import React from "react";

export default function Header(props) {
    //define our hooks
    const history = useHistory();
    const location = useLocation();
    
    //props
    const animStyle=props.animStyle;
    const setAnimStyle=props.setAnimStyle;

    //set current page to 0,1,2 depending on pathname
    let path;
    switch(location.pathname) {
        case "/" :
            path= 0;//on about page highlight about in nav
            break;
        case "/webdev":
            path= 1; //on webdev page highlight webdev in nav
            break;
        case "/gamedev":
            path= 2; //on gamedev page highlight gamedev in nav
            break;
        default :
            path = null;  //if we're not on one of the main three pages don't highlight the nav
            break;
    }

    //on initial page load ensure that the proper nav link is highlighted (or that none are)
    if(path!==animStyle[1]){
        setAnimStyle([animStyle[0], path]);
    }

    function changeScreen(screenPath, screenId) {
        //this function removes current screen from view and waits until it's out of view
        //but
        //need to ensure we dont do so if we are already on that page!
        if(animStyle[1]!==screenId) {
            setAnimStyle(["anim-exit", screenId]);
            setTimeout(()=>newScreenIn(screenPath, screenId), 400)
        }
    }

    function newScreenIn(screenPath, screenId) {
        //this function slides in the new screen
        setAnimStyle(["anim-fast", screenId]);
        history.push(screenPath);
    }

    return (
        <header className="portfolio-header">
            <div className="portfolio-header__nameplate">
                <div className="portfolio-header__title">Jackson Tarlin</div>
                <div className="portfolio-header__imagebox" ></div>
            </div>
            <nav className="portfolio-nav" >
                <ul className="portfolio-nav__bar">
                    <li className="portfolio-nav__li portfolio-nav__li--1">
                        <button onClick={()=>changeScreen("/", 0)} className={`portfolio-nav__link ${animStyle[1]===0 && "portfolio-nav__link--active"}`}>
                            About
                        </button>
                    </li>
                    <li className="portfolio-nav__li portfolio-nav__li--2">
                        <button onClick={()=>changeScreen("/webdev", 1)} className={`portfolio-nav__link ${animStyle[1]===1 && "portfolio-nav__link--active"}`}>
                            Web Dev
                        </button>
                    </li>
                    <li className="portfolio-nav__li portfolio-nav__li--3">
                        <button onClick={()=>changeScreen("/gamedev", 2)} className={`portfolio-nav__link ${animStyle[1]===2 && "portfolio-nav__link--active"}`}>
                            Game Dev
                        </button>
                    </li>
                </ul>
            </nav>
            
        </header>
    )

}