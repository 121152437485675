import "./Footer.scss";


//icon imports
import github from "../../Assets/github.png";
import linkedin from "../../Assets/linkedin.png";

export default function Footer() {

    return(
        <footer className="footer">
            <div className="footer__icon-box">
                <a href="https://github.com/JTarlin" >
                    <img src={github} alt="github" className="footer__icon" />
                </a>
                <a href="https://www.linkedin.com/in/jackson-tarlin/" >
                    <img src={linkedin} alt="linkedin" className="footer__icon"/>
                </a>
            </div>
            <div className="footer__copyright">2021 © Jackson Tarlin</div>
        </footer>
    )
}