import "./Hvac.scss";

import BackButton from "../../BackButton/BackButton";

//import image
import titleImg from "../../../Assets/hvac/hvac1.jpg"

export default function Hvac(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`hvac-box ${animClass}`}>
            <div className="hvac-content">
                <div className="hvac-title" >hvac.ai</div>
                
                <section className="hvac-desc">
                    <a href="https://hvacai.netlify.app" className="unstyled-link">
                        <img src={titleImg} alt="hvac site home screen" className="hvac-img"/>
                    </a>
                    <div className="hvac-desc__info">
                        <div className="hvac-desc__header">What is it?</div>
                        <div className="hvac-desc__description">I built the hvac.ai homepage over one weekend to learn about parllax scroll effects, which can be seen in the hero banner. The library I used was "react-scroll-parallax". The page is live, and can be found <a href="https://hvacai.netlify.app" className="unstyled-link">here</a>
                        </div>
                        <ul className="hvac-desc__stats">
                            <li className="hvac-desc__stat">Tech Stack: React, HTML, CSS, Sass, react-scroll-parallax</li>
                            <li className="hvac-desc__stat">Timeline: 3 days, Jan 30-Feb 1st 2021</li>
                        </ul>
                    </div>
                </section>
                <BackButton setAnimStyle={setAnimStyle} />
            </div>
        </div>
    )
}