import "./Thermometer.scss";

import BackButton from "../../BackButton/BackButton";

//import image
import titleImg from "../../../Assets/Thermostat/thermopic.jpg"

export default function Thermometer(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`thermo-box ${animClass}`}>
            <div className="thermo-content">
                <div className="thermo-title" >Thermostat Dashboard</div>
                
                <section className="thermo-desc">
                    <a href="https://thermoai.netlify.app" className="unstyled-link">
                        <img src={titleImg} alt="thermo site home screen" className="thermo-img"/>
                    </a>
                    <div className="thermo-desc__info">
                        <div className="thermo-desc__header">What is it?</div>
                        <div className="thermo-desc__description">This Thermostat dashboard was built as a take-home coding assignment for an interview with <a href="https://www.paritygo.com/platform/" className="unstyled-link">parity.inc</a>. The dashboard leverages Parity's api to simulate thermostat measurements in different building units and allow the building superintentdent to control temperature settings, whether automatic, heating, or cooling. The page is live, and can be found <a href="https://thermoai.netlify.app" className="unstyled-link">here</a>
                        </div>
                        <ul className="thermo-desc__stats">
                            <li className="thermo-desc__stat">Tech Stack: React, HTML, CSS, Sass, Axios</li>
                            <li className="thermo-desc__stat">Timeline: 3 days, Feb 8-Feb 10 2021</li>
                            <li className="thermo-desc__stat">Check it out on Github: <a href="https://github.com/JTarlin/thermostat" className="unstyled-link">thermostat</a></li>
                        </ul>
                    </div>
                </section>
                <BackButton setAnimStyle={setAnimStyle} />
            </div>
        </div>
    )
}