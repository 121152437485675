import "./WordWorlds.scss";

import BackButton from "../../BackButton/BackButton";

//import image
import titleImg from "../../../Assets/wordworlds/wordworlds.jpg";

export default function WordWorlds(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`words-box ${animClass}`}>
            <div className="words-content">
                <div className="words-title" >Word Worlds</div>
                
                <section className="words-desc">
                    <img src={titleImg} alt="words gameplay sceenshot" className="words-img"/>
                    <div className="words-desc__info">
                    
                        <div className="words-desc__header">What is it?</div>
                        <div className="words-desc__description">Word Worlds was a procedural dungeon map generation tool built on a python framework, with a twist. Using the Amazon voice API to parse speech, the user would describe the dungeon in words and the program would generate it. Users could specify room shapes, corridor bendiness, material type, and even whether or not they wanted a water (or lava) lake!</div>
                        <ul className="words-desc__stats">
                            <li className="words-desc__stat">Tech Stack: Python, Amazon Voice, JavaScript</li>
                            <li className="words-desc__stat">Timeline: 2 days, Hack Princeton 2018</li>
                            <li className="words-desc__stat">Devpost: You can find the project <a href="https://github.com/JTarlin/Lightspeed" className="unstyled-link">here</a></li>
                        </ul>
                    </div>
                </section>
                <BackButton setAnimStyle={setAnimStyle} />
            </div>
        </div>
    )
}