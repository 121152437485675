//package depedencies
import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

//style imports
import './App.css';

//component imports
import Header from "./Components/Header/Header";
import About from "./Components/About/About";
import WebDev from "./Components/WebDev/WebDev";
import GameDev from "./Components/GameDev/GameDev";
import Footer from "./Components/Footer/Footer";
//project imports
import Lightspeed from "./Components/Projects/Lightspeed/Lightspeed";
import Postal from "./Components/Projects/Postal/Postal";
import Labyrinth from "./Components/Projects/Labyrinth/Labyrinth";
import WordWorlds from "./Components/Projects/WordWorlds/WordWorlds";
import Hvac from "./Components/Projects/Hvac/Hvac";
import Thermometer from "./Components/Projects/Thermometer/Thermometer";

function App() {

  const [animStyle, setAnimStyle] = React.useState(["anim-slow", null]);

  return (
    <div className="App">
      <main>
        <Router>
          {/* render header regardless of page we're on */}
          <Header animStyle={animStyle} setAnimStyle={setAnimStyle}/>
          {/* otherwise content depends on route */}
          <Switch>
            <Route exact path="/" render={()=> <About animStyle={animStyle} setAnimStyle={setAnimStyle}/>}  />
            <Route exact path="/webdev" render={()=> <WebDev animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route exact path="/gamedev" render={()=> <GameDev animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/webdev/lightspeed" render={()=> <Lightspeed animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/webdev/postal" render={()=> <Postal animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/webdev/hvac" render={()=> <Hvac animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/gamedev/labyrinth" render={()=> <Labyrinth animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/gamedev/wordworlds" render={()=> <WordWorlds animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
            <Route path="/webdev/thermometer" render={()=> <Thermometer animStyle={animStyle} setAnimStyle={setAnimStyle}/>} />
          </Switch>
          {/* render footer regardless of what page we're on (sound familiar?) */}
          <Footer />
        </Router>
      </main>
    </div>
  );
}

export default App;
