import "./WebDev.scss";

//import dependencies
import React from "react";
import {useHistory} from "react-router-dom";

//image imports
import postal from "../../Assets/postal/postal-logo.png";

export default function WebDev(props) {
    //hooks
    const history=useHistory();

    //props
    const animClass=props.animStyle[0];
    const setAnimStyle = props.setAnimStyle;

    const changePage = (screenPath)=>{
        setAnimStyle(["anim-exit", null]);
        setTimeout(()=>newScreenIn(screenPath), 400);
    }

    function newScreenIn(screenPath) {
        //this function slides in the new screen
        setAnimStyle(["anim-fast", null]);
        history.push(screenPath);
    }

    return (
        <div className="portfolio-box">
        <div className={`portfolio-web ${animClass}`}>
            <div className="portfolio-web__title">
                Web Projects
            </div>
            <section className="web-projects">
                <div className="web-projects__title">Websites and more!</div>
                <div className="web-projects__text">
                    I've made all kinds of web projects. These projects run the gamut from freelance websites to React Native apps!  
                </div>
                <div className="web-projects__cards">
                    <button className="invisible-button" onClick={()=>changePage("/webdev/lightspeed")} >
                        <div className="web-projects__card web-projects__lightspeed">
                            <div className="web-projects__card-title">Lightspeed Companion</div>
                        </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/webdev/postal")} >
                        <div className="web-projects__card">
                            <img src={postal} alt="postal productions logo" className="web-projects__postal-img" />
                            <div className="web-projects__card-title">Postal Productions</div>
                        </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/webdev/thermometer")} >
                        <div className="web-projects__card web-projects__thermometer">
                            <div className="web-projects__card-title">Thermostat Dashboard</div>
                        </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/webdev/hvac")} >
                        <div className="web-projects__card web-projects__hvac">
                            <div className="web-projects__card-title">hvac.ai</div>
                        </div>
                    </button>
                </div>
            </section>
        </div>
        </div>
    )
}