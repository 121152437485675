import "./GameDev.scss";

//import dependencies
import React from "react";
import {useHistory} from "react-router-dom";

export default function GameDev(props) {
    //hooks
    const history=useHistory();

    //props
    const animClass=props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    const changePage = (screenPath)=>{
        setAnimStyle(["anim-exit", null]);
        setTimeout(()=>newScreenIn(screenPath), 400);
    }

    function newScreenIn(screenPath) {
        //this function slides in the new screen
        setAnimStyle(["anim-fast", null]);
        history.push(screenPath);
    }

    return (
        <div className="portfolio-box">
        <div className={`portfolio-game ${animClass}`}>
            <div className="portfolio-game__title">
                Game Projects
            </div>
            <section className="game-projects">
                <div className="game-projects__title">Games and other toys</div>
                <div className="game-projects__text">
                    Most of the games I've made involve some kind of procedural generation. Probably because those are the types of games I like to play! 
                </div>
                <div className="game-projects__cards">
                    <button className="invisible-button" onClick={()=>changePage("/gamedev/labyrinth")} >
                    <div className="game-projects__card game-projects__labyrinth">
                        <div className="game-projects__card-title">Labyrinth of Souls</div>
                    </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/gamedev/wordworlds")} >
                    <div className="game-projects__card game-projects__wordworlds">
                        <div className="game-projects__card-title">Word Worlds</div>
                    </div>
                    </button>
                </div>
            </section>
        </div>
        </div>
    )
}