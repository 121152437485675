//import dependencies
import React from "react";
import {useHistory} from "react-router-dom";

//styling imports
import "./About.scss";
//image imports
import postal from "../../Assets/postal/postal-logo.png";

export default function About(props) {

    //define our hooks
    const history=useHistory();

    const animStyle = props.animStyle;
    const setAnimStyle = props.setAnimStyle;

    const animClass=animStyle[0];

    const changePage = (screenPath)=>{
        setAnimStyle(["anim-exit", null]);
        setTimeout(()=>newScreenIn(screenPath), 400);
    }

    function newScreenIn(screenPath) {
        //this function slides in the new screen
        setAnimStyle(["anim-fast", null]);
        history.push(screenPath);
    }

    return (
        <div className="portfolio-box">
        <div className={`portfolio-about ${animClass}`}>
            <div className="portfolio-about__title">
                Welcome to my site!
            </div>
            <section className="who">
                <div className="who__title">Who Am I?</div>
                <div className="who__text">
                    I'm a creative and multi-talented developer and engineer with a love of making fun experiences. I think that the web is an amazing tool, allowing the best experiences to reach the biggest audiences. 
                </div>
            </section>
            <section className="what">
                <div className="what__title">What Do I Do?</div>
                <div className="what__text">
                    Professionally, I'm a full stack developer. In my spare time I design and build games. Take a look at some of my favourite projects:
                </div>
                <div className="what__projects">
                    <button className="invisible-button" onClick={()=>changePage("/webdev/lightspeed")} >
                        <div className="what__project what__lightspeed">
                            <div className="what__project-title">Lightspeed Companion</div>
                        </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/webdev/postal")} >
                        <div className="what__project what__postal">
                            <img src={postal} alt="postal productions logo" className="what__postal-img" />
                            <div className="what__project-title">Postal Productions</div>
                        </div>
                    </button>
                    <button className="invisible-button" onClick={()=>changePage("/gamedev/labyrinth")} >
                        <div className="what__project what__labyrinth">
                            <div className="what__project-title">Labyrinth of Souls</div>
                        </div>
                    </button>
                </div>
            </section>
            <section className="more-about">
                <div className="more-about__title">More about me:</div>
                <div className="more-about__lists">
                    <div className="more-about__content more-about__content--1">
                        <div className="more-about__header">Places I've Been:</div>
                        <ul className="more-about__list">
                            <li className="more-about__item">Vietnam</li>
                            <li className="more-about__item">Japan</li>
                            <li className="more-about__item">Belize</li>
                            <li className="more-about__item">Morocco</li>
                            <li className="more-about__item">Spain</li>
                            <li className="more-about__item">France</li>
                        </ul>
                    </div>
                    <div className="more-about__content more-about__content--2">
                        <div className="more-about__header">Inspirations:</div>
                        <ul className="more-about__list">
                            <li className="more-about__item">Richard Feynman</li>
                            <li className="more-about__item">Albert Einstein</li>
                            <li className="more-about__item">Kurt Vonnegut</li>
                            <li className="more-about__item">Hayao Miyazaki</li>
                        </ul>
                    </div>
                    <div className="more-about__content more-about__content--3">
                        <div className="more-about__header">Hobbies and Interests:</div>
                        <ul className="more-about__list">
                            <li className="more-about__item">The Toronto Raptors</li>
                            <li className="more-about__item">Dungeons & Dragons</li>
                            <li className="more-about__item">Nuclear Throne</li>
                            <li className="more-about__item">Watching Starcraft</li>
                            <li className="more-about__item">Reading Sci-Fi and Fantasy</li>

                        </ul>
                    </div>
                </div>
            </section>
        </div>
        </div>
    )
}