import "./Lightspeed.scss";

//import image
import titleImg from "../../../Assets/lightspeed/lightspeedPic.png";
import lightspeedVid from "../../../Assets/lightspeed/jackson-tarlin-video.mp4";

//componenet imports
import BackButton from "../../BackButton/BackButton";

export default function Lightspeed(props) {
    
    const animClass = props.animStyle[0];
    const setAnimStyle=props.setAnimStyle;

    return (
        <div className={`lightspeed-box ${animClass}`}>
            <div className="lightspeed-content">
                <div className="lightspeed-title">Lightspeed Companion</div>
                
                <section className="ls-description">
                    <img src={titleImg} alt="lightspeed app home screen" className="lightspeed-img"/>
                    <div className="ls-description__info">
                        <div className="ls-description__header">What is it?</div>
                        <div className="ls-description__description">The Lightspeed Companion is a React Native app built to accompany the tabletop role-playing game Lightspeed. It simplifies character creation and makes it easier to organize and share game content with others. My brother is the lead game designer behind Lightspeed so I might be a bit biased, but it's probably the most fun tabletop RPG ever. </div>
                        <ul className="ls-description__stats">
                            <li className="ls-description__stat">Tech Stack: React Native, Firebase Realtime DB, Node JS</li>
                            <li className="ls-description__stat">Timeline: 3 weeks, Dec 2020 to Jan 2021</li>
                            <li className="ls-description__stat">Github: Check it out <a href="https://github.com/JTarlin/Lightspeed" className="unstyled-link">here</a></li>
                        </ul>
                    </div>
                </section>
                <div className="ls-description__header">App Walkthrough:</div>
                <video  alt="lightspeed app walkthrough" className="lightspeed-video" controls>
                    <source src={lightspeedVid} type="video/mp4"/>
                </video>
                <BackButton setAnimStyle={setAnimStyle}/>
                {/* <section className="ls-video">
                    <div className="ls-video__header">See it in action:</div>
                    <video src="%"/>
                </section> */}
            </div>
        </div>
    )
}